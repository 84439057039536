import { useContext, useRef, useState, useEffect } from 'react';
import { ApiAcuityAppointment, EAPDescriptionType, User } from "../../utils/ApiTypes";
import "./TalkToSomeone.scss";
import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonItemDivider } from "@ionic/react";
import HelpIcon from "../../assets/images/help-gray.svg";
import PhoneBlack from "../../assets/images/phone-black.svg";
import { LiveChat } from "../LiveChat/LiveChat";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { useTranslation } from 'react-i18next';
import { SCHEDULE_APPOINTMENT_PATH } from "../../utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { CancelAppointmentModal } from "../ScheduleAppointment/Modals/CancelAppointmentModal";
import { CancelConfirmationModal } from '../ScheduleAppointment/Modals/CancelConfirmationModal';
import { ModifyAppointmentModal } from '../ScheduleAppointment/Modals/ModifyAppointmentModal';
import AnalyticsService from "../../misc/AnalyticsService";
import { Appointment } from '../ScheduleAppointment/Appointment';
import { Feature, useHasFeatureEnabled } from '../Feature/Feature';
import ScheduleAppointmentButton from '../ScheduleAppointment/ScheduleAppointmentButton';
import AskAQuestionButton from '../AskAQuestion/AskAQuestionButton';
import CallSupportButton from '../CallSupport/CallSupportButton';
import { EapDescription } from './EapDescription';
import useCompanyBenefits from '../CustomHooks/useCompanyBenefits';

interface TalkToSomeoneProps {
    user: User;
    isMobileWidth: boolean;
    showDescription: boolean;
    showDescriptionInPopover: boolean;
    isCondensed: boolean;
    isComponentVisible: boolean;
    className?: string;
    isPhoneButtonTriggered?: boolean;
    isChatWithUsButtonTriggered?: boolean;
    subtitle: string | null;
    eapDescription: EAPDescriptionType;
}

export function TalkToSomeone(props: TalkToSomeoneProps) {
    const location = useLocation();
    const accordionGroup = useRef<HTMLIonAccordionGroupElement | null>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [isCancelAppointmentModalOpen, setIsCancelAppointmentModalOpen] = useState<boolean>(false);
    const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false);
    const [isModifyAppointmentModalOpen, setIsModifyAppointmentModalOpen] = useState<boolean>(false);
    const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);
    const {
        handleGeneralError,
        language,
        handleUserError
    } = useContext(ApplicationContext);
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const history = useHistory();
    const { benefits, benefitsStatus } = useCompanyBenefits();
    const phoneButtonRef = useRef<HTMLAnchorElement | null>(null);

    const isWpo: boolean = useHasFeatureEnabled("talk-to-someone-schedule-wpo-appointment");
    const isCca: boolean = useHasFeatureEnabled("talk-to-someone-schedule-appointment");
    const hasScheduling = isWpo || isCca;
    const hasLiveChat: boolean = useHasFeatureEnabled("talk-to-someone-live-chat");

    useEffect(() => {
        if (!props.isComponentVisible || !props.isPhoneButtonTriggered || !phoneButtonRef) return;
        phoneButtonRef.current?.click();
    }, [props.isComponentVisible, props.isPhoneButtonTriggered]);

    async function getAppointment(): Promise<ApiAcuityAppointment | undefined> {
        const nowDate = new Date().toISOString();
        try {
            if(isWpo) {
                return await JourneyApiClient.getScheduledWpoAppointment();
            } else {
                return await JourneyApiClient.getScheduledAppointment(nowDate);
            }
        } catch (e) {
            handleGeneralError("Could not fetch appointment", e, undefined, { 
                contextName: "Get Appointment TalkToSomeone", 
                contextData: {
                    nowDate: nowDate,
                    acuityError: e,
                    userId: props.user.id,
                    language: language,
                    isWpo: isWpo
                } 
            });
        }
    }
    const appointmentsQuery = useQuery<Promise<ApiAcuityAppointment | undefined>, unknown, ApiAcuityAppointment>
        (["getScheduledAppointment"], getAppointment); 

    const appointmentMutation = useMutation({
        mutationFn: ({ appointmentId }: { appointmentId: number }) => {
            if(isWpo) {
                return JourneyApiClient.cancelWpoAppointment(appointmentId.toString());
            } else {
                return JourneyApiClient.cancelAppointment(appointmentId);
            }
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getScheduledAppointment'] })
            setIsCancelAppointmentModalOpen(false);
            setIsCancelConfirmationModalOpen(true);
            trackCancelAppointment();
        }
    })

    async function trackCancelAppointment() {
        let properties = {
            supplier: isWpo ? "WPO" : "Acuity",
        }
        await AnalyticsService.trackUserAction("cancel_appointment", location.pathname, properties);
    }

    async function handleAppointmentCancel() {
        if (!appointmentsQuery.data || isNaN(appointmentsQuery.data.appointmentId)) return;
        let appointmentId: number = appointmentsQuery.data.appointmentId;
        try {
            setIsCancelLoading(true);
            await appointmentMutation.mutateAsync({ appointmentId });
        } catch (e) {
            handleGeneralError("Could not cancel appointment", e);
        }
        setIsCancelLoading(false);
    }

    function handleScheduleAppointmentButton() {
        if(props.user.company.type === "trial"){
            handleUserError(t("Schedule Appointment is not available for Trial users."), " ");
        } 
        else if (appointmentsQuery.status === "success" && appointmentsQuery.data) {
            setIsModifyAppointmentModalOpen(true);
        } else {
            history.push(SCHEDULE_APPOINTMENT_PATH);
        }
    }

    function handleRescheduleButtonClicked() {
        history.push(SCHEDULE_APPOINTMENT_PATH);
        setIsModifyAppointmentModalOpen(false);
    }

    const numberOfPhones = benefits?.supportPhones?.length ?? 0;
    let numberOfButtons = 1;
    if (numberOfPhones > 0) numberOfButtons++;
    if (hasLiveChat) numberOfButtons++;
    if (hasScheduling) numberOfButtons++;
    let appointmentScheduled = appointmentsQuery.status === "success" && appointmentsQuery.data;

    return (
        <div className={`eap-benefits-container ${props.isCondensed && "small"} ${props.className}`}>
            <div className={"eap-benefits-top-container"}>
                {!appointmentsQuery.isLoading && appointmentsQuery.status === "success" && appointmentsQuery.data &&
                    <Appointment
                        onCancelButtonClicked={() => setIsCancelAppointmentModalOpen(true)}
                        onRescheduleButtonClicked={() => history.push(SCHEDULE_APPOINTMENT_PATH)}
                        appointment={appointmentsQuery.data}
                    />
                }
                <div className={"eap-benefits-text-wrapper"}>
                    <div className="eap-benefits-text-container">
                        <div className="eap-benefits-icon-container">
                            <IonIcon src={PhoneBlack} className={"eap-benefits-icon"}/>
                        </div>
                        <div>
                            <div className="h6-bold eap-benefits-title">{t("Want to talk with someone?")}
                                {props.showDescriptionInPopover && !props.isMobileWidth &&
                                    <Popover
                                        containerClassName={"popover"}
                                        isOpen={isPopoverOpen}
                                        positions={['top']}
                                        padding={10}
                                        onClickOutside={() => setIsPopoverOpen(false)}
                                        content={({ position, childRect, popoverRect }) => (
                                            <ArrowContainer
                                                position={position}
                                                childRect={childRect}
                                                popoverRect={popoverRect}
                                                arrowColor={'rgba(0, 0, 0, 0.72)'}
                                                arrowSize={10}
                                                className='popover-arrow-container'
                                                arrowClassName='popover-arrow'
                                            >
                                                <div className="popover-text"><EapDescription eapDescription={props.eapDescription} /></div>
                                            </ArrowContainer>
                                        )}
                                    >
                                        <IonIcon className="eap-benefits-title-icon" src={HelpIcon} onMouseEnter={() => setIsPopoverOpen(true)} />
                                    </Popover>
                                }
                            </div>
                            {props.subtitle && <div className="caption eap-benefits-subtitle">{props.subtitle}</div>}
                        </div>
                    </div>
                    <div className={`talk-to-someone-component buttons-${numberOfButtons} ${props.isCondensed && "small"} ${appointmentScheduled && "appointment-scheduled"}`}>

                        {numberOfPhones > 0 && benefitsStatus === "success" && benefits &&
                            <CallSupportButton 
                                className='benefits-button header-6-variant'
                                benefitsInfo={benefits}
                            />
                        }

                       <AskAQuestionButton 
                            className='benefits-button header-6-variant'
                            iconClassName={'benefits-icon'}
                       />

                        <Feature feature='talk-to-someone-live-chat'>
                            <LiveChat
                                isChatWithUsButtonTriggered={props.isChatWithUsButtonTriggered}
                                isTrial={props.user.company.type === "trial"}
                                currentUser={props.user}
                                isComponentVisible={props.isComponentVisible}
                            />
                        </Feature>

                        {appointmentsQuery.status === "success" && hasScheduling &&
                            <ScheduleAppointmentButton 
                                onClick={handleScheduleAppointmentButton} 
                                className="benefits-button header-6-variant"
                                hasAppointmentScheduled={appointmentsQuery.status === "success" && !!appointmentsQuery.data}/>
                        }

                    </div>
                </div>
            </div>
            {props.showDescription && <div className="benefits-description-container">
                <div className="talk-to-someone-line-divider-container">
                    <IonItemDivider className="talk-to-someone-line-divider" />
                </div>

                {props.isMobileWidth ?
                    <IonAccordionGroup className="talk-to-someone-accordion-group" ref={accordionGroup}>
                        <IonAccordion className="accordion-wrapper" value={"talk-to-someone-STEP1"}>
                            <IonItem slot={"header"} className={"description-title h6-bold"}>{t("I call my EAP when …")}</IonItem>
                            <div className="accordion-container" slot={"content"}>
                                <EapDescription eapDescription={props.eapDescription} />
                            </div>
                        </IonAccordion>
                    </IonAccordionGroup>
                    : <EapDescription eapDescription={props.eapDescription} />
                }
            </div>}
            {appointmentsQuery.status === "success" && appointmentsQuery.data &&
                <CancelAppointmentModal
                    appointment={appointmentsQuery.data}
                    isOpen={isCancelAppointmentModalOpen}
                    handleCloseModal={() => setIsCancelAppointmentModalOpen(false)}
                    handleAppointmentCancel={handleAppointmentCancel}
                    isCancelLoading={isCancelLoading}
                />}
            <CancelConfirmationModal
                isOpen={isCancelConfirmationModalOpen}
                handleCloseModal={() => setIsCancelConfirmationModalOpen(false)}
            />
            {appointmentsQuery.status === "success" && appointmentsQuery.data && <ModifyAppointmentModal
                isOpen={isModifyAppointmentModalOpen}
                handleCloseModal={() => setIsModifyAppointmentModalOpen(false)}
                appointment={appointmentsQuery.data}
                onCancelButtonClicked={() => setIsCancelAppointmentModalOpen(true)}
                onRescheduleButtonClicked={handleRescheduleButtonClicked} />}
        </div>)
}