import './BenefitsPage.scss';
import * as React from 'react';
import {useContext} from 'react';
import {Benefits} from "../../components/Benefits/Benefits";
import {IonContent, IonPage} from '@ionic/react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { useTranslation } from 'react-i18next';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import useCompanyBenefits from '../../components/CustomHooks/useCompanyBenefits';

export const BenefitsPage: React.FC = () => {

    const {currentUser } = useContext(ApplicationContext);
    const { t } = useTranslation();
    const { benefits, benefitsStatus } = useCompanyBenefits();

    return (
        <IonPage className={`benefits-page`} id="main-content">

            <ResponsiveNavigationMenu title={t('benefits')} />

            <IonContent>
                {
                    (function() {
                        if (benefitsStatus === "loading") {
                            return <PleaseWait/>;
                        } else if (benefitsStatus === "success" && benefits) {
                            return <Benefits benefitsInfo={benefits} currentUser={currentUser} />;
                        } else {
                            return <div>{t("Something went wrong.")}</div>;
                        }
                    })()
                }
            </IonContent>
        </IonPage>
    );

}
