import * as React from 'react';
import {useContext} from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./EapSupportPhonePage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { EapSupportPhones } from '../../components/EapSupportPhones/EapSupportPhones';
import useCompanyBenefits from '../../components/CustomHooks/useCompanyBenefits';

export const EapSupportPhonesPage: React.FC = () => {

    const { currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const { benefits, benefitsStatus } = useCompanyBenefits();

    return(
        <IonPage className="eap-support-phone-page">
            
            <ResponsiveNavigationMenu title={"Support Phones"} />
            
            <IonContent className="eap-support-phone-page-content">
                <div className="eap-support-phone-page-container">
                    {currentUser && benefitsStatus === "success" && benefits &&
                    <EapSupportPhones isComponentVisible={isComponentVisible} benefits={benefits}/>}
                </div>
            </IonContent>
        </IonPage>
    )
};
