import { IonButton, IonInput, IonItem, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import "./AskAQuestion.scss";
import React, { useState, useContext, useEffect } from "react";
import { CheckboxAssessmentQuestion } from "../Assessment/CheckboxAssessmentQuestion";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ApiCountry } from "../../utils/ApiTypes";
import { useQuery } from "@tanstack/react-query";
import "flag-icons/css/flag-icons.min.css";
import { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import { CountryCode } from "libphonenumber-js";
import { HOME_PATH } from "../../utils/utils";

export class ResourcesEmailAnswer {
    phoneNumber: string | null = null;
    preferredContactMethod: "phone" | "email" | null = null;
    areasOfConcern: string | null = null;
    question: string | null = null;
}

interface AskAQuestionProps {
    isComponentVisible: boolean;
}

export const AskAQuestion: React.FC<AskAQuestionProps> = (props: AskAQuestionProps) => {

    const history = useHistory();
    const location = useLocation();
    const { currentUser, handleGeneralError, handleGeneralMessage, isMobileApp, language } = useContext(ApplicationContext);
    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [phoneCountry, setPhoneCountry] = useState<string>(language?.countryId ?? 'US');
    const [inputPlaceholder, setInputPlaceholder] = useState<string>(`+${getCountryCallingCode(language?.countryId as CountryCode ?? 'US' as CountryCode)} (#)`);
    const [formValues, setFormValues] = useState<ResourcesEmailAnswer>({
        phoneNumber: null,
        preferredContactMethod: 'phone',
        areasOfConcern: null,
        question: null,
    });
    const [phoneInScreen, setPhoneInScreen] = useState<string | null | undefined>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.isComponentVisible) {
            setFormValues({
                phoneNumber: null,
                preferredContactMethod: 'phone',
                areasOfConcern: null,
                question: null,
            });
            setPhoneInScreen(null);
            setIsFormComplete(false);
            setPhoneCountry(language?.countryId ?? 'US');
            setInputPlaceholder(`+${getCountryCallingCode(language?.countryId as CountryCode ?? 'US' as CountryCode)} (#)`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isComponentVisible]);

    useEffect(() => {
        const phoneNumberInputIsValid: boolean = (formValues.phoneNumber !== null && (isValidPhoneNumber(formValues.phoneNumber) || isValidPhoneNumber(formValues.phoneNumber.replace('+', `+${getCountryCallingCode(phoneCountry as CountryCode)}`))));
        if (currentUser &&
            (formValues.preferredContactMethod) && (formValues.areasOfConcern && formValues.areasOfConcern !== "[]")) {
            if (formValues.preferredContactMethod === "phone" && !phoneNumberInputIsValid) {
                setIsFormComplete(false);
            } else {
                setIsFormComplete(true);
            }
        } else {
            setIsFormComplete(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, phoneCountry]);

    async function trackFormSubmission() {
        await AnalyticsService.trackUserAction("ask_a_question_submission", location.pathname);
    }

    function handleValueChange(name: string, value: string | null | undefined, event?: any) {
        setFormValues({ ...formValues, [name]: value });
    }

    function setPhoneNumber(phoneNumber: string | null | undefined) {
        setPhoneInScreen(phoneNumber);
        if (!phoneNumber) {
            setFormValues({ ...formValues, 'phoneNumber': null });
            return;
        }
        // clean phone number
        // Remove all non-numeric characters except for "+"
        let cleanedNumber = phoneNumber.replace(/[^\d+]/g, '');

        // Ensure it starts with a "+" for international format
        if (!cleanedNumber.startsWith("+")) {
            cleanedNumber = `+${getCountryCallingCode(phoneCountry as CountryCode)}${cleanedNumber}`;
        }

        setFormValues({ ...formValues, 'phoneNumber': cleanedNumber });
    }

    const handleSubmitForm = async () => {
        try {
            await JourneyApiClient.submitResourcesAskQuestionForm(formValues);
            await trackFormSubmission();
            history.push(HOME_PATH);
            handleGeneralMessage(t("Success"), t("One of our team members will contact you within one business day via email."));
        } catch(error: any) {
            handleGeneralError(t("Could not submit form"), error);
        }
    };

    const areasOfConcern: string[] = [
        t("Anger Management"),
        t("Anxiety"),
        t("Depression"),
        t("Drug/Alcohol & Addiction Concerns"),
        t("Dependent Care Concerns"),
        t("Family or Relationships Concerns"),
        t("Grief and Loss"),
        t("Legal/Financial Services"),
        t("Smoking Cessation"),
        t("Stress Management"),
        t("Workplace Concerns"),
        t("Other"),
    ];

    async function fetchCountries(): Promise<ApiCountry[] | undefined> {
        try{
            return await JourneyApiClient.getCompanyCountries('all');
        } catch (e) {
            handleGeneralError("Could not fetch countries", e);
        }
    }
    const countriesQuery = useQuery<Promise<ApiCountry[] | undefined>, unknown, ApiCountry[]>
    (["getCompanyCountries"], fetchCountries);

    async function changePhoneCountry(e: CustomEvent){
        setPhoneCountry(e.detail.value!);
        setInputPlaceholder(`+${getCountryCallingCode(e.detail.value!)} (#)`);
        e.stopPropagation();
    }

    return (
        <div className={`ask-a-question-component`}>
            <div className="top-container">
                <div className="top-container-copy header-4">{t("Ask a Question")}</div>
                <div className="top-container-copy body-medium">{t("After completing the form below, one of our team members will contact you within one business day via email. If this is a life-threatening emergency, please contact emergency services for assistance.")}</div>
            </div>
            <div className="bottom-container">
                <CheckboxAssessmentQuestion
                    isComponentVisible={props.isComponentVisible}
                    assessmentQuestion={t("Please indicate your area(s) of concern *")}
                    onValueChange={(concerns) => handleValueChange("areasOfConcern", JSON.stringify((concerns)))}
                    assessmentValueOptions={areasOfConcern}
                    checkboxLimit={areasOfConcern.length}
                    columns={2} />

                <IonTextarea
                    onIonChange={(e) => handleValueChange("question", e.detail.value)}
                    className="body-small question-input-field"
                    placeholder={t("Ask a question...") || ""}
                    value={formValues.question}
                    autoGrow={true}
                    rows={4}
                />

                <div className="phone-number-title header-5">{t("What is your phone number? *")}</div>
                <div className="phone-number-copy body-medium">{t("We are committed to providing you the support you need. Please enter your phone number for an additional follow up.")}</div>

                {countriesQuery.isSuccess && countriesQuery.data.length > 0 &&
                    <div className="phone-number-container">
                        <div className="phone-number-select">
                            <IonSelect
                                interface={isMobileApp ? "alert" : "popover"}
                                onIonChange={changePhoneCountry}
                                className="ion-select button-medium"
                                value={phoneCountry}
                                selectedText=" "
                                >
                                {countriesQuery.data.map((country) => {
                                        return <IonSelectOption value={country.id} key={country.id}>
                                            {t(country.name)}
                                        </IonSelectOption>;
                                })}
                            </IonSelect>
                            {phoneCountry && (
                                <div className="flag-display">
                                    <span className={`fi fi-${phoneCountry.toLowerCase()}`}></span>
                                </div>
                            )}
                        </div>
                        <div className="phone-number-input">
                            <IonItem className={"phone-input"}>
                                <IonInput className={"button-medium"} placeholder={inputPlaceholder ?? t("Enter your number")} type="tel" autocomplete="tel" inputmode="tel" value={phoneInScreen} onIonChange={e => setPhoneNumber(e.detail.value!)} />
                            </IonItem>
                        </div>
                    </div>
                }
            </div>
            <div className="submit-button-container">
                <IonButton
                    disabled={!isFormComplete || !currentUser}
                    className="submit-button button-medium-variant"
                    onClick={handleSubmitForm}
                >
                    {t('submit')}
                </IonButton>
            </div>
        </div>
    )

}