import { useQuery } from '@tanstack/react-query';
import { EAPSupportPhone, GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import { useContext } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { JourneyApiClient } from '../../utils/JourneyApiClient';

// Custom hook that uses useQuery to fetch languages
export default function useCompanyBenefits(): {benefits: GetCompanyBenefitsInfoResponse | null, benefitsStatus: string, defaultPhoneNumber: EAPSupportPhone | null, phoneNumbersInCurrentLanguageCountry: EAPSupportPhone[]} {

    const {currentUser, language, handleGeneralError} = useContext(ApplicationContext);

    async function getBenefits(): Promise<GetCompanyBenefitsInfoResponse | undefined> {
        try {
            return await JourneyApiClient.getCompanyBenefitsInfo();
        } catch (e) {
            handleGeneralError("Could not get benefits", e, undefined, { 
                contextName: "Get Benefits", 
                contextData: {
                    error: e,
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company
                } 
            });
        }
    }

    const benefitsQuery = useQuery<Promise<GetCompanyBenefitsInfoResponse | undefined>, unknown, GetCompanyBenefitsInfoResponse>
        (["getCompanyBenefitsInfo"], getBenefits,
            {
                enabled: !!currentUser, // Only fetch when companyId is provided
                refetchOnMount: false, // Do not refetch on mount
            }
        );

    // Priority of displayed phone number: current language country (selected in UI) > user's country > default country (US)
    const phoneNumbersInCurrentLanguageCountry = benefitsQuery.status === 'success' ? benefitsQuery?.data.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === language?.countryId.toLowerCase()) : [];
    const phoneNumbersInUserCountry = benefitsQuery.status === 'success' ? benefitsQuery?.data.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === currentUser?.country.toLowerCase()) : [];
    const phoneNumbersInDefaultCountry = benefitsQuery.status === 'success' ? benefitsQuery?.data.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === 'us') : [];
    const phoneNumberDefault = phoneNumbersInCurrentLanguageCountry.length > 0 ? phoneNumbersInCurrentLanguageCountry[0] : phoneNumbersInUserCountry.length > 0 ? phoneNumbersInUserCountry[0] : phoneNumbersInDefaultCountry.length > 0 ? phoneNumbersInDefaultCountry[0] : benefitsQuery?.data?.supportPhones[0];

    return {
        benefits: benefitsQuery.data ?? null,
        benefitsStatus: benefitsQuery.status,
        defaultPhoneNumber: phoneNumberDefault ?? null,
        phoneNumbersInCurrentLanguageCountry: phoneNumbersInCurrentLanguageCountry ?? [],
    };
};
